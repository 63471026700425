import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import {
  callAPI,
  getRepaymentData,
  getTransactionData,
} from "../../API/FP/Actions";
import { contextObj, dateFormat } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import { repaymentMockData } from "../../MockData/FinancePartnerMock";

function RequestRepayment({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  // const { state } = useLocation();
  // let navigate = useNavigate();
  const location = useLocation();
  const requestData = location.state?.requestData;
  const token = location.state?.authToken;
  const [repaymentDetails, setRepaymentDetails] = useState(repaymentMockData);
  const [isLoading, setIsLoading] = useState(false);
  const [utrNumbers, setUtrNumber] = useState("");
  const [auth, setAuth] = useAuth();

  const fetchTransactionData = async (trxId, token) => {
    try {
      const transactionResponse = await getTransactionData(auth?.token);
      console.log("TR : ", transactionResponse);
      if (
        transactionResponse &&
        transactionResponse.res &&
        transactionResponse.res.data
      ) {
        const filteredTransactions = transactionResponse.res.data.filter(
          (transaction) =>
            transaction.repayment?.collectionTrnxDetailsId &&
            transaction.repayment.collectionTrnxDetailsId === trxId
        );

        if (filteredTransactions.length > 0) {
          // Concatenate all UTR numbers into a single string
          const utrNumbers = filteredTransactions
            .map((t) => t.utrNo)
            .join(", ");
          console.log("UTR : ", utrNumbers);
          setUtrNumber(utrNumbers);
        } else {
          setUtrNumber("Not available"); // Set this if no matching transactions are found
        }
      }
    } catch (error) {
      console.error("Error fetching transaction data: ", error);
    }
  };

  useEffect(() => {
    const fetchRepaymentData = async () => {
      setIsLoading(true);
      try {
        const repaymentResponse = await getRepaymentData(auth?.token);
        // console.log("Repayment Response", repaymentResponse)
        if (
          repaymentResponse &&
          repaymentResponse.res &&
          repaymentResponse.res.data
        ) {
          const filteredData = repaymentResponse.res.data.filter(
            (repayment) =>
              repayment.financerequest?.id &&
              repayment.financerequest.id === requestData.financerequest.id
          );
          setRepaymentDetails(filteredData);
          // console.log("Filtered Data", filteredData)
          if (filteredData.length > 0) {
            const trxId = filteredData[0].collectionTrnxDetailsId;
            console.log(trxId); // Assuming each repayment detail has an id
            // await fetchTransactionData(trxId, auth?.token);
          }
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    // fetchRepaymentData();
  }, [requestData, auth?.token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // if (!repaymentDetails || repaymentDetails.length === 0) {
  //   return <div>No repayment data available</div>;
  // }

  const repayment = repaymentDetails[0];

  let tableHeaderNames = [
    "Finance request ID",
    "Amount to be disbursed",
    "Creditors Account Name",
    "Virtual Account number",
    "IFSC code",
  ];
  let tableData = [
    {
      financeRequestId: "REQ-2023-123456",
      amountDisbursed: "8,80,000",
      creditorsAccountName: "Paisabuddy Escrow account",
      virtualAccountNumber: "PBD110000000001",
      ifscCode: "IDFB0080202",
    },
  ];

  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Finance request ID</th>
                    <td>
                      {repaymentDetails.length > 0
                        ? repayment.financeRequestId
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Disbursed Date</th>
                    <td>
                      {repaymentDetails.length > 0
                        ? dateFormat(new Date(repayment.dbmtDateTime), 0)
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Repayment Due Date</th>
                    <td>
                      {repaymentDetails.length > 0
                        ? dateFormat(new Date(repayment.repaymentDueDate), 0)
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Deposit Account Name</th>
                    <td>
                      {repaymentDetails.length > 0
                        ? repayment.sourceAccountName
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Deposit Account Number</th>
                    <td>
                      {repaymentDetails.length > 0
                        ? repayment.sourceAccountNumber
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>IFSC code</th>
                    <td>
                      {repaymentDetails.length > 0 ? repayment.ifscCode : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Total Repayment Amount</th>
                    <td>
                      {repaymentDetails.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={repayment.totalRepaymentAmount}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount Repaid So far</th>
                    <td>
                      {repaymentDetails.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={repayment.amountRepayed}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount to be RePaid</th>
                    <td>
                      {repaymentDetails.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={repayment.amountToBePaid}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Repayment Date</th>
                    <th>Amount Repaid</th>
                    <th>Status</th>
                    <th>UTR</th>
                    <th>Reference Number</th>
                  </tr>
                  <tr>
                    <td>
                      {repaymentDetails.length > 0
                        ? dateFormat(new Date(repayment.repaymentDate), 0)
                        : "N/A"}
                    </td>
                    <td>
                      {repaymentDetails.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={repayment.amountRepayed}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {repaymentDetails.length > 0 ? repayment.status : "N/A"}
                    </td>
                    <td>
                      {" "}
                      {repaymentDetails.length > 0 ? repayment?.utrNo : "N/A"}
                    </td>
                    <td>
                      {repaymentDetails.length > 0
                        ? repayment.referenceNumber
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <h4 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Please make the payment as per the above details and confirm to
              us.
            </h4> */}
          </div>
          {/* <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#219916",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
              }}
              onClick={() => navigate("/request_log")}
            >
              Confirm Re-Payment
            </button>
          </div> */}
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestRepayment;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
