import React from "react";

const Faq = ({ fill = "#4A3AFF" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_565_1835)">
      <path
        d="M21.5 4H18V2.5C18 1.121 16.879 0 15.5 0H2.5C1.122 0 0 1.121 0 2.5V18.354C0 18.962 0.333 19.52 0.87 19.807C1.115 19.938 1.382 20.002 1.649 20.002C1.968 20.002 2.286 19.909 2.562 19.726L6 17.434V21H17.349L21.439 23.727C21.714 23.911 22.033 24.003 22.352 24.003C22.619 24.003 22.886 23.939 23.13 23.808C23.667 23.52 24 22.963 24 22.355V6.5C24 5.121 22.879 4 21.5 4ZM2.007 18.895C1.805 19.031 1.556 19.042 1.342 18.926C1.128 18.812 1 18.598 1 18.355V2.5C1 1.673 1.673 1 2.5 1H15.5C16.327 1 17 1.673 17 2.5V16H6.349L2.007 18.895ZM23 22.355C23 22.598 22.872 22.812 22.658 22.926C22.441 23.042 22.196 23.029 21.992 22.895L17.651 20H7V17H18V5H21.5C22.327 5 23 5.673 23 6.5V22.355ZM10 13.001C10 13.553 9.552 14.001 9 14.001C8.448 14.001 8 13.553 8 13.001C8 12.449 8.448 12.001 9 12.001C9.552 12.001 10 12.449 10 13.001ZM7 6.001H6C6 5.112 6.391 4.274 7.071 3.703C7.752 3.131 8.651 2.892 9.539 3.049C10.748 3.261 11.74 4.253 11.952 5.462C12.176 6.735 11.571 8.009 10.446 8.63C9.954 8.901 9.499 9.315 9.499 10.501V11.001H8.499V10.501C8.499 9.177 8.964 8.304 9.963 7.754C10.725 7.334 11.119 6.502 10.966 5.635C10.826 4.833 10.168 4.175 9.365 4.033C8.762 3.929 8.174 4.082 7.713 4.469C7.259 4.85 7 5.408 7 6.001Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_565_1835">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Faq;
