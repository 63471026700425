import axios from "axios";

export const getOpenRequests = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/request-offers?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getOfferRequests = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/placed-offers?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getOfferData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/accepted-offers?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getDisbursementData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/disbursements?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getRepaymentData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/repayments?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getTransactionData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/collection-transaction-details`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getTradeData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/trades?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      return { res };
    })
    .catch((err) => {
      return err;
    });
};

export const patchOfferStatus = async (authToken, requestData) => {
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_BACKEND_URL}api/request-offers/${requestData.id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getAllInvoicesDetails = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/trades?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getAnchorTraderDetails = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/anchor-traders?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const sendPlacedOfferDetails = async (authToken, requestData) => {
  var config = {
    method: "post",
    // url: `${process.env.REACT_APP_BACKEND_URL}api/placed-offers/${requestData.id}`,
    url: `${process.env.REACT_APP_BACKEND_URL}api/placed-offers`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getPFTCreditRecommendationScores = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/individual-assessments?size=5000`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getObservations = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/cre-observations`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const callAPI = async (authToken, requestData, type) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_URL}controller/${type}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};
