import React from "react";

const Help = ({ fill = "#4A3AFF" }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1293_9463)">
      <path
        d="M23.6862 11.237L19.2412 2.876C18.7902 2.027 17.9142 1.5 16.9552 1.5H7.06825C6.11125 1.5 5.23625 2.025 4.78425 2.872L0.31725 11.237C-0.10575 12.031 -0.10575 12.979 0.31725 13.772L4.78325 22.128C5.23525 22.974 6.11025 23.499 7.06625 23.499H16.9532C17.9122 23.499 18.7882 22.972 19.2393 22.123L23.6842 13.762C24.1052 12.972 24.1052 12.027 23.6842 11.237H23.6862ZM22.8032 13.294L18.3582 21.655C18.0812 22.176 17.5443 22.5 16.9552 22.5H7.06825C6.48125 22.5 5.94425 22.178 5.66725 21.658L1.20025 13.302C0.93425 12.803 0.93325 12.207 1.20025 11.708L5.66725 3.343C5.94525 2.823 6.48225 2.5 7.06925 2.5H16.9562C17.5442 2.5 18.0812 2.824 18.3592 3.345L22.8042 11.706C23.0682 12.203 23.0672 12.797 22.8032 13.294ZM12.5013 14.5H11.5013V6.5H12.5013V14.5ZM13.0013 17.5C13.0013 18.052 12.5533 18.5 12.0013 18.5C11.4493 18.5 11.0013 18.052 11.0013 17.5C11.0013 16.948 11.4493 16.5 12.0013 16.5C12.5533 16.5 13.0013 16.948 13.0013 17.5Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1293_9463">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Help;
