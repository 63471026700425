import { TextField } from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import React, { useState } from "react";
// import { verifyOTP } from "../Actions";

function ProfileGSTOTP({ gstId, gstUsername, accessToken }) {
  const [otp, setOtp] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [showWrongOtp, setShowWrongOtp] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setShowSuccess(true);
    // const oResponse = await verifyOTP(gstId, gstUsername, otp, accessToken);
    // try {
    //   console.log(oResponse);
    //   if (oResponse.res.status === 200) {
    //     oResponse.res.data?.data?.error
    //       ? setShowWrongOtp(true)
    //       : setShowSuccess(true);
    //   }
    // } catch (err) {
    //   // setIsLoading(false);
    //   setShowFail(true);
    //   console.log("Error", err);
    // }
  };
  return (
    <div>
      <div className="main-signinGST">
        <form className="form" onSubmit={handleSubmit}>
          <div
            style={{
              paddingBottom: "15px",
            }}
          >
            <TextField
              id="otp"
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{
                sx: {
                  color: "#4A3AFF",
                  fontWeight: "bold",
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#4A3AFF",
                  },
                },
              }}
              label="Verify OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter your OTP"
              style={{ width: "100%" }}
              variant="standard"
              // disabled
              required
            />
          </div>
          <div>
            If you didn't receive a code!{" "}
            <span style={{ color: "green" }}>Resend</span>
          </div>

          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              style={{
                padding: "8px",
                color: "white",
                backgroundColor: "green",
                border: "none",
                marginTop: "15px",
                borderRadius: "5px",
              }}
              onClick={() => {
                setShowFail(false);
                setShowSuccess(false);
                setShowWrongOtp(false);
              }}
            >
              Verify
            </button>
          </div>
          {showSuccess && (
            <div
              style={{ color: "green", textAlign: "center", marginTop: "10px" }}
            >
              OTP Verified Successfully!
            </div>
          )}
          {showFail && (
            <div
              style={{ color: "red", textAlign: "center", marginTop: "10px" }}
            >
              Error Occured
            </div>
          )}
          {showWrongOtp && (
            <div
              style={{ color: "red", textAlign: "center", marginTop: "10px" }}
            >
              Wrong OTP Entered
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default ProfileGSTOTP;
