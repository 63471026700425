import React, { useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Tooltip, Typography } from "@mui/material";
import { dateFormat } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { invoicesMockData } from "../../MockData/FinancePartnerMock";

function TradeReport({ invoicesData, financeRequestData }) {
  const { state } = useLocation();
  let navigate = useNavigate();

  let tableHeaderNames = [
    "Invoice Date",
    "Invoice Number",
    "Anchor Trader",
    "Trade Partner ",
    "Trade Amount (INR)",
    "Term (Days)",
  ];

  return (
    <>
      <div>
        <RequestInformation>
          <div className="main-content">
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    {tableHeaderNames.map((x) => {
                      return <th>{x}</th>;
                    })}
                  </tr>

                  {invoicesMockData.map((item) => {
                    return (
                      <tr>
                        <td>{dateFormat(new Date(item?.invoiceDate), 0)}</td>
                        <td>{item?.invoiceNumber}</td>
                        <td>
                          {item?.anchorTraderName.length > 20 ? (
                            <Tooltip
                              title={`${item?.anchorTraderName}`}
                              placement="top"
                            >
                              <div
                                className={
                                  `${item?.anchorTraderName}`.length > 20 &&
                                  `text-ellipse`
                                }
                                style={{ width: "120px" }}
                              >
                                {item?.anchorTraderName}
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={
                                `${item?.anchorTraderName}`.length > 20 &&
                                `text-ellipse`
                              }
                              style={{ width: "120px" }}
                            >
                              {item?.anchorTraderName}
                            </div>
                          )}
                        </td>
                        <td>
                          {item?.tradePartnerName.length > 20 ? (
                            <Tooltip
                              title={`${item?.tradePartnerName}`}
                              placement="top"
                            >
                              <div
                                className={
                                  `${item?.tradePartnerName}`.length > 20 &&
                                  `text-ellipse`
                                }
                                style={{ width: "120px" }}
                              >
                                {item?.tradePartnerName}
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={
                                `${item?.tradePartnerName}`.length > 20 &&
                                `text-ellipse`
                              }
                              style={{ width: "120px" }}
                            >
                              {item?.tradePartnerName}
                            </div>
                          )}
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={item?.tradeAmount}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </td>
                        <td>{item?.invoiceTerm}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default TradeReport;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
