import {
  Box,
  Checkbox,
  Input,
  TextareaAutosize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";

function ProfileDetails({ setValue }) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [checkBoxValue2, setCheckboxValue2] = useState(false);
  const [universalclick, setuniversalclick] = useState(false);
  const [popupbtnopen, setpopupbtnopen] = useState(false);

  const gstTableData = [
    {
      id: 1,
      name: "Srikara Hospitals",
      gstNumber: "36AAIFP3688H1ZA",
      kycStatus: true,
      action: "View KYC Details",
    },
    {
      id: 2,
      name: "primetextiles",
      gstNumber: "36AAIFP3688H1ZK",
      kycStatus: false,
      action: "View KYC Details",
    },
    {
      id: 3,
      name: "AbhiRam Textiles",
      gstNumber: "36AAIFP3688H1ZM",
      kycStatus: false,
      action: "View KYC Details",
    },
    {
      id: 4,
      name: "Fab India",
      gstNumber: "36AAIFP3688H1ZZ",
      kycStatus: true,
      action: "View KYC Details",
    },
  ];

  const openpopup = () => {
    setpopupbtnopen(true);
  };
  const closepopup = () => {
    setpopupbtnopen(false);
  };
  const editbtnclick = () => {
    setuniversalclick(true);
  };

  const cancelclick = () => {
    setuniversalclick(false);
  };
  return (
    <div className="body-text">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{
            borderRadius: "5px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            padding: "8px 8px",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            opacity: universalclick ? "0.5" : "1",
            width: "90px",
            marginRight: universalclick ? "10px" : "0",
          }}
          onClick={editbtnclick}
        >
          Edit Details
        </button>

        {universalclick && (
          <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "8px 8px",
              cursor: "pointer",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            }}
            onClick={cancelclick}
          >
            Cancel
          </button>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "20%", color: "#000", fontWeight: "bold" }}>
            Personal Details
          </div>
          <div
            style={{ width: "80%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="outlined-basic"
                  label="Name*"
                  defaultValue="Vijay Prathap Reddy"
                  variant="outlined"
                  disabled={universalclick !== true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "5%",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="outlined-basic"
                  label="Phone number*"
                  defaultValue="8688284439"
                  variant="outlined"
                  disabled={universalclick !== true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "5%",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="outlined-basic"
                  label="Email address*"
                  defaultValue="info@primetextiles.in"
                  variant="outlined"
                  disabled={universalclick !== true}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "5%" }}>
          <div style={{ width: "20%", color: "#000", fontWeight: "bold" }}>
            Business details
          </div>
          <div
            style={{ width: "80%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="outlined-basic"
                  label="Company name*"
                  defaultValue="Prime Textiles"
                  variant="outlined"
                  disabled={universalclick !== true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "5%",
                }}
              >
                <Box width={"100%"}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Company address*"
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    multiline
                    // rows={4}
                    defaultValue="PLOT NO-245, PRIME TEXTILES, IDA RAMPUR, HANAMKONDA, Warangal Urban, Telangana, 506003"
                    inputProps={{ style: { fontSize: 14, width: "500px" } }}
                    variant="outlined"
                    disabled={universalclick !== true}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "Column",
            flexWrap: "wrap",
          }}
        >
          <h4>Have multiple GST's? No problem add below </h4>
          <button
            type="button"
            style={{
              borderRadius: "5px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "8px 8px",
              cursor: "pointer",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
              opacity: universalclick ? "1" : "0.5",
              width: "100px",
            }}
            disabled={universalclick !== true}
            onClick={openpopup}
          >
            {" "}
            Add GST
          </button>
          <Dialog
            open={popupbtnopen}
            onClose={closepopup}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              style: { borderRadius: 20 },
            }}
          >
            <DialogTitle>Fill up the GST details</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={closepopup}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Divider />
            <DialogContent>
              <DialogContentText>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                      margin: "5px",
                    }}
                    id="standard-basic"
                    label="GST Number "
                    name={"GST Number"}
                    variant="outlined"
                    required
                  />

                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                      margin: "5px",
                    }}
                    id="standard-basic"
                    label="Company Name "
                    name={"Company Name "}
                    variant="outlined"
                    required
                  />

                  <button
                    type="button"
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "8px 8px",
                      cursor: "pointer",
                      height: "fit-content",
                      boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                      margin: "5px",
                      width: "100px",
                      position: "absolute",
                      right: 8,
                      bottom: 8,
                    }}
                    //onClick={handlemultiplegstsave}
                  >
                    Save
                  </button>
                </form>
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>

        <>
          <h3>GSTIN details</h3>
          <table
            style={{
              width: "100%",
              margin: "1px",
              borderCollapse: "collapse",
              padding: "10px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "5%",
                    border: "1px solid #ddd",
                    padding: "8px",
                  }}
                >
                  S.No.
                </th>
                <th
                  style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                  }}
                >
                  Company Name
                </th>
                <th
                  style={{
                    width: "15%",
                    border: "1px solid #ddd",
                    padding: "8px",
                  }}
                >
                  Company GST
                </th>
                <th
                  style={{
                    width: "15%",
                    border: "1px solid #ddd",
                    padding: "8px",
                  }}
                >
                  kyc Completed Status
                </th>
                <th
                  style={{
                    width: "15%",
                    border: "1px solid #ddd",
                    padding: "8px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {gstTableData?.map((item, index) => (
                <tr key={index} style={{ border: "1px solid black" }}>
                  <td style={{ padding: "8px" }}>{index + 1}</td>
                  <td style={{ padding: "8px" }}>{item?.name}</td>
                  <td style={{ padding: "8px" }}>{item?.gstNumber}</td>
                  <td style={{ padding: "8px" }}>
                    {item?.kycStatus == null ? "false" : String(item.kycStatus)}
                  </td>
                  <td style={{ padding: "8px" }}>
                    <button
                      type="button"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "5px 15px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        opacity: universalclick !== true ? 0.5 : 1,
                      }}
                      onClick={() => {
                        setValue("3");
                      }}
                      disabled={universalclick !== true}
                    >
                      View KYC Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
        <h4 style={{ marginBottom: "0px", marginTop: "60px" }}>
          {" "}
          <Checkbox
            {...label}
            value={checkBoxValue}
            onChange={() => setCheckboxValue(!checkBoxValue)}
          />
          I have read and accepted the{" "}
          <span style={{ color: "#4A3AFF", cursor: "pointer" }}>
            Terms and Conditions
          </span>
          .
        </h4>
        <h4 style={{ marginBottom: "5px", marginTop: "0px" }}>
          {" "}
          <Checkbox
            {...label}
            value={checkBoxValue2}
            onChange={() => setCheckboxValue2(!checkBoxValue2)}
          />
          I hereby declare that the information given above is true to the best
          of my knowledge and belief.
        </h4>
        <div style={{ marginTop: "15px", textAlign: "center" }}>
          <button
            style={{
              borderRadius: "2px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "2px",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
              opacity: checkBoxValue && checkBoxValue2 ? "1" : "0.5",
            }}
            //   onClick={() => navigate("/request_log")}
          >
            Save Details
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
