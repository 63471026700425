import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import Footer from "../Common/Footer";
import ProFinTechLogoCompressed from "../images/ProFinTechLogoCompressed.png";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { multigstController, signUp } from "../Common/Constants";
import { controllerAPI } from "../API/FP/Actions";

function Signup() {
  const [auth, setAuth] = useAuth();
  const [spinnerLoading, setIsspinnerLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [noneState, setNoneState] = useState(false);
  const [formData, setFormData] = useState({
    firmName: "",
    ownerName: "",
    emailAddress: "",
    gstNumber: "",
    typeOfFirm: "",
    phoneNumber: "",
    address: "",
    sellerBuyer: "",
    bankNbfc: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "sellerBuyer") {
      setNoneState(value === "NONE");
    }
  };

  const handleNone = (event) => {
    setNoneState(true);
    setFormData((prevState) => ({
      ...prevState,
      sellerBuyer: "FINANCE_PARTNER",
    }));
  };

  // const handleNone = (e) => {
  //   setNoneState(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    console.log("formData is", formData);
    setIsspinnerLoading(true);
    // try {
    //   const constructObjSignup = signUp(formData);
    //   const savegstdetails = await controllerAPI(
    //     "post",
    //     auth?.token,
    //     constructObjSignup,
    //     "signup"
    //   );

    //   if (savegstdetails.res.status === 200) {
    //     setOpenSnackbar(true);
    //     setApiResultMessage("Signup Successful");
    //     setAlertSeverity("success");
    //   }
    // } catch (err) {
    //   console.log("Error", err);
    //   setOpenSnackbar(true);
    //   setApiResultMessage("Error Occured. Please try later");
    //   setAlertSeverity("error");
    // } finally {
    //   setIsspinnerLoading(false);
    // }
    navigate("/creditbazaar/passwordsetup");
    setTimeout(() => {
      setIsspinnerLoading(false);
      // Perform the actual login action here
    }, 2000);
    e.preventDefault();
    try {
      /*const res = await axios.post(`${process.env.REACT_APP_API}/user/signup`, {
        firstName,
        lastName,
        number,
        email,
        password,
      });
      if (res.data.success) {
        alert(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/profile", {
          state: {
            id: res.data.user.id,
            email: email,
            number: number,
            firstName: firstName,
            lastName: lastName,
          },
        });
      } else {
        alert(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/profile", {
          state: {
            id: res.data.user.id,
            email: email,
            number: number,
            firstName: firstName,
            lastName: lastName,
          },
        });
      }
    */
    } catch (error) {
      console.log(error);
      alert("something went wrong");
    }
  };

  return (
    <>
      {spinnerLoading ? (
        <>
          <CircularProgress
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
            }}
          />
        </>
      ) : (
        <>
          <Sign1>
            <div className="Logo">
              <img src={ProFinTechLogoCompressed} />
            </div>
            <div className="Sign1">
              <div className="parentDiv">
                {" "}
                <div
                  style={{
                    color: "black",
                    fontSize: 25,
                    fontFamily: "Open Sans",
                    fontWeight: "700",
                    wordWrap: "break-word",
                    marginBottom: "20px",
                  }}
                >
                  Account Registration
                </div>
                <form className="form" onSubmit={handleSubmit}>
                  <div className="formwrap">
                    <div className="TotalSection">
                      <div className="firstSection">
                        <div className="content">
                          <label
                            style={{
                              width: "100%",
                              color: "#333333",
                              fontSize: 18,
                              fontFamily: "Open Sans",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            Name of firm *
                          </label>
                          <input
                            type="text"
                            value={formData.firmName}
                            onChange={handleChange}
                            id="firmName"
                            name="firmName"
                            required
                            placeholder="Firm Name"
                          />
                        </div>

                        <div className="content">
                          <label
                            style={{
                              width: "100%",
                              color: "#333333",
                              fontSize: 18,
                              fontFamily: "Open Sans",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            Owner of the firm
                          </label>
                          <input
                            type="text"
                            value={formData.ownerName}
                            onChange={handleChange}
                            id="ownerName"
                            name="ownerName"
                            required
                            placeholder="Owner of Firm"
                          />
                        </div>

                        <div className="content">
                          <label
                            style={{
                              width: "100%",
                              color: "#333333",
                              fontSize: 18,
                              fontFamily: "Open Sans",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            Email Address
                          </label>
                          <input
                            type="text"
                            value={formData.emailAddress}
                            onChange={handleChange}
                            id="emailAddress"
                            name="emailAddress"
                            required
                            placeholder="ABC@gmail.com"
                          />
                        </div>

                        <div className="content">
                          <label
                            style={{
                              width: "100%",
                              color: "#333333",
                              fontSize: 18,
                              fontFamily: "Open Sans",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            GST Number
                          </label>
                          <input
                            type="text"
                            value={formData.gstNumber}
                            onChange={handleChange}
                            id="gstNumber"
                            name="gstNumber"
                            required
                            placeholder="GST Number"
                          />
                        </div>
                      </div>
                      <div className="secondSection">
                        <div className="content">
                          <label
                            style={{
                              width: "100%",
                              color: "#333333",
                              fontSize: 18,
                              fontFamily: "Open Sans",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            Type of firm *
                          </label>
                          <input
                            type="text"
                            value={formData.typeOfFirm}
                            onChange={handleChange}
                            id="typeOfFirm"
                            name="typeOfFirm"
                            required
                            placeholder="Firm Type"
                          />
                        </div>

                        <div className="content">
                          <label
                            style={{
                              width: "100%",
                              color: "#333333",
                              fontSize: 18,
                              fontFamily: "Open Sans",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            Phone number
                          </label>
                          <input
                            type="tel"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            id="phoneNumber"
                            name="phoneNumber"
                            required
                            placeholder="123 456 789"
                            pattern=".{10,}" // Ensures at least 7 characters
                            title="Phone number must be at least 10 digits long"
                          />
                        </div>

                        <div className="content">
                          <label
                            style={{
                              width: "100%",
                              color: "#333333",
                              fontSize: 18,
                              fontFamily: "Open Sans",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            value={formData.address}
                            onChange={handleChange}
                            id="address"
                            name="address"
                            required
                            placeholder="Company location"
                          />
                        </div>

                        <div>
                          <div className="content">
                            <label
                              style={{
                                width: "100%",
                                color: "#333333",
                                fontSize: 18,
                                fontFamily: "Open Sans",
                                fontWeight: "600",
                                wordWrap: "break-word",
                              }}
                            >
                              Are you a seller or buyer?
                            </label>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "20px",
                                }}
                              >
                                <input
                                  style={{ width: "auto", height: "auto" }}
                                  type="radio"
                                  id="seller"
                                  name="sellerBuyer"
                                  value="ANCHOR_TRADER"
                                  onChange={handleChange}
                                  disabled={noneState === true}
                                  checked={
                                    formData.sellerBuyer === "ANCHOR_TRADER"
                                  }
                                  required
                                />
                                <label
                                  htmlFor="seller"
                                  style={{
                                    color: "#333333",
                                    fontSize: 14,
                                    fontFamily: "Open Sans",
                                    fontWeight: "600",
                                    wordWrap: "break-word",
                                    marginLeft: "8px",
                                  }}
                                >
                                  Seller
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "20px",
                                }}
                              >
                                <input
                                  style={{ width: "auto", height: "auto" }}
                                  type="radio"
                                  id="buyer"
                                  name="sellerBuyer"
                                  value="TRADE_PARTNER"
                                  onChange={handleChange}
                                  disabled={noneState === true}
                                  checked={
                                    formData.sellerBuyer === "TRADE_PARTNER"
                                  }
                                  required
                                />
                                <label
                                  htmlFor="buyer"
                                  style={{
                                    color: "#333333",
                                    fontSize: 14,
                                    fontFamily: "Open Sans",
                                    fontWeight: "600",
                                    wordWrap: "break-word",
                                    marginLeft: "8px",
                                  }}
                                >
                                  Buyer
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  style={{ width: "auto", height: "auto" }}
                                  type="radio"
                                  id="none"
                                  name="sellerBuyer"
                                  value="NONE"
                                  onChange={handleNone}
                                  checked={
                                    formData.sellerBuyer === "FINANCE_PARTNER"
                                  }
                                  required
                                />
                                <label
                                  htmlFor="none"
                                  style={{
                                    color: "#333333",
                                    fontSize: 14,
                                    fontFamily: "Open Sans",
                                    fontWeight: "600",
                                    wordWrap: "break-word",
                                    marginLeft: "8px",
                                  }}
                                >
                                  None of the above
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {noneState === true ? (
                          <>
                            <div className="content">
                              <label
                                style={{
                                  width: "100%",
                                  color: "#333333",
                                  fontSize: 18,
                                  fontFamily: "Open Sans",
                                  fontWeight: "600",
                                  wordWrap: "break-word",
                                }}
                              >
                                Are you a Bank/NBFC?
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "20px",
                                  }}
                                >
                                  <input
                                    style={{ width: "auto", height: "auto" }}
                                    type="radio"
                                    id="bank"
                                    name="bankNbfc"
                                    value="Bank"
                                    onChange={handleChange}
                                    checked={formData.bankNbfc === "Bank"}
                                    required
                                  />
                                  <label
                                    htmlFor="bank"
                                    style={{
                                      color: "#333333",
                                      fontSize: 14,
                                      fontFamily: "Open Sans",
                                      fontWeight: "600",
                                      wordWrap: "break-word",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    Bank
                                  </label>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    style={{ width: "auto", height: "auto" }}
                                    type="radio"
                                    id="nbfc"
                                    name="bankNbfc"
                                    value="NBFC"
                                    onChange={handleChange}
                                    checked={formData.bankNbfc === "NBFC"}
                                  />
                                  <label
                                    htmlFor="nbfc"
                                    style={{
                                      color: "#333333",
                                      fontSize: 14,
                                      fontFamily: "Open Sans",
                                      fontWeight: "600",
                                      wordWrap: "break-word",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    NBFC
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ButtonsPlacement">
                    <button className="Backbtn" onClick={() => navigate("/")}>
                      Back
                    </button>
                    <button type="submit">Save</button>
                  </div>
                </form>
              </div>
            </div>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={alertSeverity}
                sx={{ width: "100%" }}
              >
                {apiResultMessage}
              </Alert>
            </Snackbar>
          </Sign1>
        </>
      )}
    </>
  );
}

export default Signup;

const Sign1 = styled.div`
  .Sign1 {
    display: flex;
    margin-bottom: 5vh;
    justify-content: center;
    body {
      background-color: #e5e4e2;
    }
  }
  /* Styles for the page background */

  /* Styles for the form wrapper */
  .parentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ButtonsPlacement {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .formwrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

    width: fit-content;
  }
  .Logo {
    margin: 10px 30px;
  }

  /* Styles for the welcome text */
  .formwrap > div:first-child {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .content {
    margin: 10px;
  }
  /* Styles for the form */
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .TotalSection {
    display: flex;
    flex-direction: row;
    width: 900px;
    padding: 30px;
    justify-content: space-between;
  }
  .firstSection {
    display: flex;
    flex-direction: column;
  }

  .secondSection {
    display: flex;
    flex-direction: column;
  }
  /* Styles for the form labels */
  label {
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    color: #333333;
    font-size: 18px; /* Added 'px' */
    font-family: "Open Sans";
  }

  /* Styles for the form inputs */
  input {
    height: 30px;
    width: 300px;
    padding: 5px;
  }

  /* Styles for the submit button */
  button[type="submit"] {
    font-size: 18px;
    background-color: #297fff;
    font-family: "Open Sans";
    font-weight: 400; /* Remove quotes */
    color: white;
    height: 45px;
    width: 120px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    align-self: flex-end; /* Corrected */
  }

  .Backbtn {
    color: black; /* Corrected to lowercase */
    height: 45px;
    width: 120px;
    background-color: white;
    border: 1px #404040 solid;
    display: flex; /* To use justify-content and align-items */
    justify-content: center; /* Remove quotes */
    align-items: center; /* Remove quotes */
    padding: 20px 36px;
    border-radius: 2px;
    cursor: pointer;
    align-self: flex-start; /* Corrected */
  }

  /* Styles for the "have an account?" and "Login for free" links */
  div > a {
    margin-top: 10px;
    text-decoration: none;
    color: orange;
  }

  /* Optional hover effect for links */
  div > a:hover {
    text-decoration: underline;
  }
`;
