import React, { useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Input,
  Link,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  CreateRequestsInvoicesMockData,
  CreateRequestsInvoicesSelectedMockData,
} from "../MockData/AnchorTraderMock";
import CloseIcon from "@mui/icons-material/Close";

function AnchorTraderRequestInformation() {
  const navigate = useNavigate();
  const [requiredAmount, setRequiredAmount] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [Selectedvalue, setSelectedvalue] = useState(false);
  const [SelectedTableVisible, setSelectedTableVisible] = useState(false);
  const [popupbtnopen, setpopupbtnopen] = useState(null);
  const ariaLabel = { "aria-label": "description" };
  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    let month = date.toLocaleString("default", { month: "short" }); //months from 1-12
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();
    return month + " " + day + ", " + year;
  }

  const selectedtrades = [
    {
      tradeDate: "2023-08-01",
      tradeNo: "T12345",
      tradeAmount: 100000,
      targetName: "Partner A",
      term: 30,
      tradePartnerReview: "Satisfactory",
      tradePartnerConfirmation: "Approved",
    },
    {
      tradeDate: "2023-06-20",
      tradeNo: "T12347",
      tradeAmount: 200000,
      targetName: "Partner C",
      term: 90,
      tradePartnerReview: "Satisfactory",
      tradePartnerConfirmation: "Approved",
    },
  ];
  const filteredarraydata = [
    {
      tradeDate: "2023-08-01",
      tradeNo: "T12345",
      tradeAmount: 100000,
      targetName: "Partner A",
      term: 30,
      tradePartnerReview: "Satisfactory",
      tradePartnerConfirmation: "Approved",
    },
    {
      tradeDate: "2023-07-15",
      tradeNo: "T12346",
      tradeAmount: 150000,
      targetName: "Partner B",
      term: 60,
      tradePartnerReview: "Excellent",
      tradePartnerConfirmation: "Not Approved",
    },
    {
      tradeDate: "2023-06-20",
      tradeNo: "T12347",
      tradeAmount: 200000,
      targetName: "Partner C",
      term: 90,
      tradePartnerReview: "Satisfactory",
      tradePartnerConfirmation: "Approved",
    },
  ];

  const getCreditScore = (gst) => {
    // let t = presentIndividualAssessment.filter(
    //   (item) => item.tradePartnerGST === gst
    // );
    // return t[0]?.creditScore;
  };
  const openpopup = () => {
    setpopupbtnopen(true);
  };

  const closepopup = () => {
    setpopupbtnopen(false);
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  console.log("1111 requiredAmount", selectedInvoices);

  const totalAmountSelected = selectedInvoices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.amount,
    0
  );
  return (
    <>
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "30px",
            }}
          >
            {/* <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="rgba(74, 58, 255, 0.5)"
                // href="/financesdashboard"
                onClick={() => {
                  navigate("/creditbazaar/buyer_dashboard");
                }}
                className="custom-link"
              >
                Dashboard
              </Link>
              <Typography color="#4A3AFF">Request for finance</Typography>
            </Breadcrumbs> */}

            <div className="header">
              <h3 style={{ margin: "0px" }} className="section-heading">
                Request for finance
              </h3>
              <Typography>
                To initiate a finance request, below is a list of active trades.
                Choose the trades as per your financing requirements.
              </Typography>
            </div>
            <div className="header">
              {/* <h3 style={{ margin: "0px" }} className="section-heading">
                Enter Amount
              </h3> */}
              <Typography>
                <div className="removeArrow">
                  <Input
                    inputProps={ariaLabel}
                    value={requiredAmount}
                    placeholder="Enter Required Amount"
                    type="number"
                    min="0"
                    InputProps={{ inputProps: { min: 0 } }}
                    autoFocus
                    sx={{
                      "& input::placeholder": {
                        fontSize: "15px",
                        fontWeight: "bold",
                      },
                    }}
                    onKeyPress={preventMinus}
                    onChange={(e) => {
                      setRequiredAmount(e.target.value);
                    }}
                  />
                  <button
                    style={{
                      borderRadius: "2px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "7px 20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      borderRadius: "2px",
                      height: "fit-content",
                      opacity:
                        requiredAmount && requiredAmount !== "0" ? "1" : "0.5",
                    }}
                    disabled={
                      requiredAmount && requiredAmount !== "0" ? false : true
                    }
                    onClick={() => {
                      setSelectedInvoices(
                        CreateRequestsInvoicesSelectedMockData
                      );
                      setSelectedvalue(true);
                    }}
                  >
                    Enter
                  </button>
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </Finance1>

      <Dash className="body-text">
        {showTable && (
          <div className="main-content">
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Trade Partner Name</th>
                    <th>Amount (INR)</th>
                    <th>Term (Days)</th>
                    <th>Trade Partner Review</th>
                    <th>
                      <input
                        type="checkbox"
                        className="clickable"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedInvoices(CreateRequestsInvoicesMockData);
                            setSelectedvalue(true);
                          } else {
                            setSelectedInvoices([]);
                          }
                        }}
                      />
                    </th>
                  </tr>

                  {CreateRequestsInvoicesMockData
                    // .filter((item) => item.status !== "AWAITING_ACCEPTANCE")
                    .map((val, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{val?.date}</td>
                          <td>{val?.invoiceNumber}</td>

                          <td>{val?.tradePartnerName}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={val?.amount}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </td>
                          <td>{val?.term}</td>
                          <td>
                            <span
                              style={{
                                padding: "5px 20px",
                                backgroundColor:
                                  val?.tradePartnerReview === "Approved"
                                    ? "rgba(216, 247, 230, 1)"
                                    : "rgba(74, 58, 255, 0.2)",
                                color:
                                  val?.tradePartnerReview === "Approved"
                                    ? "#00692A"
                                    : "#4A3AFF",
                                borderRadius: "100px",
                              }}
                            >
                              {val?.tradePartnerReview}
                            </span>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              className="clickable"
                              checked={
                                selectedInvoices.filter(
                                  (x) => x.invoiceNumber === val.invoiceNumber
                                ).length > 0
                              }
                              onChange={(e) => {
                                console.log(
                                  "11111 eeeeeeeee",
                                  selectedInvoices.filter(
                                    (x) => x.invoiceNumber === val.invoiceNumber
                                  )
                                );
                                // setSelectedInvoices([...selectedInvoices, val]);
                                if (e.target.checked) {
                                  setSelectedInvoices([
                                    ...selectedInvoices,
                                    val,
                                  ]);
                                } else {
                                  setSelectedInvoices(
                                    selectedInvoices.filter(
                                      (x) =>
                                        x.invoiceNumber !== val.invoiceNumber
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {requiredAmount && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginTop: "15px",
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  Requested amount:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    INR{" "}
                    <NumericFormat
                      displayType="text"
                      value={requiredAmount}
                      thousandsGroupStyle="lakh"
                      thousandSeparator=","
                    />
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginLeft: "20px",
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  Total selected trade amount:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    INR{" "}
                    <NumericFormat
                      displayType="text"
                      value={totalAmountSelected}
                      thousandsGroupStyle="lakh"
                      thousandSeparator=","
                    />
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginLeft: "20px",
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  Tenure:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    <NumericFormat
                      displayType="text"
                      value={60}
                      thousandsGroupStyle="lakh"
                      thousandSeparator=","
                    />{" "}
                    days
                  </span>
                </div>
              </div>
            )}
            <div style={{ color: "#4A3AFF" }}>
              If you find the tenure or trades unsatisfactory, please re-enter
              the amount again
            </div>

            {Selectedvalue === true ? (
              <button
                onClick={() => {
                  openpopup();
                }}
                style={{
                  borderRadius: "2px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                  width: "130px",
                  margin: "10px",
                }}
              >
                {" "}
                Select Custom Trades{" "}
              </button>
            ) : (
              <></>
            )}
            <Dialog
              open={popupbtnopen}
              onClose={() => {
                closepopup();
              }}
              fullWidth
              maxWidth="md"
              PaperProps={{
                style: { borderRadius: 20 },
              }}
            >
              <DialogTitle>Filtered data</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closepopup}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                <DialogContentText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    You can choose desired trade from here upto{" "}
                    <span style={{ fontWeight: "bold" }}>
                      450000/-
                      {"  "} INR {"  "}
                      <NumericFormat
                        displayType="text"
                        thousandsGroupStyle="lakh"
                        thousandSeparator=","
                      />
                    </span>
                    <div className="record-details">
                      <table
                        style={{
                          width: "100%",
                          padding: "10px",
                          marginBottom: "20px",
                          border: "1px solid #c6c6c6",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Date
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Id
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Amount (INR)
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Partner Name
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Term (Days)
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              Trade Partner Review
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredarraydata.map((invoice, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {new Date(
                                  invoice?.tradeDate
                                ).toLocaleDateString() || "-"}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {invoice.tradeNo || "-"}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                <NumericFormat
                                  displayType="text"
                                  value={invoice.tradeAmount}
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                />
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {invoice.targetName || "-"}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {invoice.term || "-"}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "2px 15px",
                                    backgroundColor:
                                      invoice.tradePartnerConfirmation ===
                                      "Approved"
                                        ? "rgba(216, 247, 230, 1)"
                                        : "rgba(74, 58, 255, 0.2)",
                                    color:
                                      invoice.tradePartnerConfirmation ===
                                      "Approved"
                                        ? "#00692A"
                                        : "#4A3AFF",
                                    borderRadius: "100px",
                                    display: "block",
                                    textAlign: "center",
                                  }}
                                >
                                  {invoice.tradePartnerConfirmation ===
                                  "Approved"
                                    ? invoice.tradePartnerReview
                                    : "Not approved"}
                                </span>
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                <input type="checkbox" className="clickable" />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <button
                      type="button"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "8px 8px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        margin: "5px",
                        width: "100px",
                        position: "absolute",
                        right: 8,
                        bottom: 8,
                      }}
                      onClick={() => {
                        closepopup();
                        setSelectedTableVisible(true);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
            <div>
              {SelectedTableVisible === true ? (
                <table
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginBottom: "20px",
                    border: "1px solid #c6c6c6",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Date
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Trade Id
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Trade Amount (INR)
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Trade Partner Name
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Term (Days)
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Trade Partner Review
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedtrades.map((invoice, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          {new Date(invoice?.tradeDate).toLocaleDateString() ||
                            "-"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          {invoice.tradeNo || "-"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          <NumericFormat
                            displayType="text"
                            value={invoice.tradeAmount}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          {invoice.targetName || "-"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          {invoice.term || "-"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              padding: "2px 15px",
                              backgroundColor:
                                invoice.tradePartnerConfirmation === "Approved"
                                  ? "rgba(216, 247, 230, 1)"
                                  : "rgba(74, 58, 255, 0.2)",
                              color:
                                invoice.tradePartnerConfirmation === "Approved"
                                  ? "#00692A"
                                  : "#4A3AFF",
                              borderRadius: "100px",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            {invoice.tradePartnerConfirmation === "Approved"
                              ? invoice.tradePartnerReview
                              : "Not approved"}
                          </span>
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="clickable"
                            checked={true}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <div style={{ marginTop: "15px", textAlign: "center" }}>
          <button
            style={{
              borderRadius: "2px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "2px",
              height: "fit-content",
              opacity: selectedInvoices.length !== 0 ? "1" : "0.5",
            }}
            disabled={selectedInvoices.length !== 0 ? false : true}
            onClick={() => navigate("/creditbazaar/placed-requests")}
          >
            Submit
          </button>
        </div>
      </Dash>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default AnchorTraderRequestInformation;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
