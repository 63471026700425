import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import newLogoExpand from "../../components/images/logoExpand.svg";
import ProFinTechlogo from "../../components/images/ProFinTech-logo.png";
import Footer from "../Common/Footer";
import { CheckBox } from "@mui/icons-material";
import Signup from "./Signup";

function Signin() {
  const [role, setRole] = useState("seller");
  const [auth, setAuth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [organizationID, setOrganizationID] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  //routing
  const handleNavigate = (res) => {
    const roles = res?.data?.roles;
    setRole(res?.data?.defaultPersona);
    if (res?.data?.defaultPersona === "ANCHOR_TRADER") {
      navigate("/creditbazaar/anchortrader-profile");
    } else if (res?.data?.defaultPersona === "TRADE_PARTNER") {
      navigate("/creditbazaar/buyer-request-information");
    } else if (res?.data?.defaultPersona === "FINANCE_PARTNER") {
      navigate("/creditbazaar/offer-information");
    } else if (res?.data?.defaultPersona === "PFT") {
      navigate("/creditbazaar/AcceptedOffer");
    } else {
      navigate("/creditbazaar/offer-information");
    }
  };

  useEffect(() => {
    const rememberedUsername = localStorage.getItem("username");
    const rememberedorganizationID = localStorage.getItem("organizationID");
    const rememberMeChecked = localStorage.getItem("rememberMe") === "true";

    if (rememberedorganizationID && rememberedUsername && rememberMeChecked) {
      setUsername(rememberedUsername);
      setOrganizationID(rememberedorganizationID);
      setRememberMe(true);
    }
  }, []);

  ///login
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      delete axios.defaults.headers.common["Authorization"];
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/tenant/signin`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-TenantID": `${organizationID}`,
          },
        }
      );

      setTimeout(() => {
        setIsLoading(false);
        // Perform the actual login action here
      }, 2000);
      // console.log(res.data);
      if (res.status === 200) {
        // alert(res.data.message);
        setAuth({
          ...auth,
          user: res ? res.data.username : localStorage.getItem("auth").user,
          userDisplayName: res && res.data.companyName,
          token: res
            ? res.data.accessToken
            : localStorage.getItem("auth").token,
          userDetails: res.data,
        });
        // console.log({ auth });
        localStorage.setItem("auth", JSON.stringify(res.data));
        if (rememberMe) {
          localStorage.setItem("username", username);
          localStorage.setItem("organizationID", organizationID);
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("organizationID");
          localStorage.removeItem("rememberMe");
        }

        handleNavigate(res);
      } else {
        setError("Invalid credentials. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid credentials. Please try again.");
        setIsLoading(false);
      } else {
        /* setAuth({
          ...auth,
          user: res.data.username,
          token: res.data.accessToken,
        });*/
        setError("Something went wrong. Please try again later.");
        setIsLoading(false);
      }
    }
  };

  console.log({ auth });
  return (
    <>
      <Sign1>
        <div className="TotalWrap">
          <div style={{ margin: "20px" }}>
            <img src={ProFinTechlogo} />
          </div>

          <div className="Sign1">
            <div className="formwrap">
              <div className="content">Welcome to Credit Bazaar</div>
              <div className="small-content">Please login to your account</div>
              <form className="form" onSubmit={handleSubmit}>
                <label htmlFor="username" style={{ marginLeft: "-195px" }}>
                  Organization ID
                </label>
                <input
                  type="txt"
                  value={organizationID}
                  onChange={(e) => {
                    setOrganizationID(e.target.value);
                    if (error) setError(null);
                  }}
                  id="orgid"
                  name="organizationId"
                  placeholder="Enter Organization ID  "
                  className={error ? "error" : ""}
                  required
                />
                <label htmlFor="username" style={{ marginLeft: "-235px" }}>
                  Username
                </label>
                <input
                  type="txt"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    if (error) setError(null);
                  }}
                  id="username"
                  name="username"
                  placeholder="Enter Username"
                  className={error ? "error" : ""}
                  required
                />
                <label htmlFor="name" style={{ marginLeft: "-240px" }}>
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (error) setError(null);
                  }}
                  id="password"
                  name="password"
                  placeholder="*************"
                  className={error ? "error" : ""}
                  required
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "15px",
                      width: "100%",
                      textAlign: "start",
                    }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      Remember me
                    </label>
                  </div>

                  <div
                    style={{
                      marginBottom: "15px",
                      width: "100%",
                      textAlign: "end",
                    }}
                  >
                    {/*
                      <Link
                        to={"/forgotpassword"}
                        style={{ textDecoration: "underline" }}
                      >
                        Forgot Password ?
                      </Link>
                   */}
                  </div>
                </div>
                {error && <div className="error-message">{error}</div>}
                <button type="submit" className="login-button">
                  {isLoading ? (
                    <div className="spinner"></div> // Spinner will be displayed when loading
                  ) : (
                    "Log In" // Button text when not loading
                  )}
                </button>
              </form>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {
                  <p>
                    Don’t have an account?{"  "}
                    <Link
                      to="/creditbazaar/signup"
                      style={{ textDecoration: "underline" }}
                    >
                      Sign Up
                    </Link>
                  </p>
                }
              </div>
            </div>
          </div>
        </div>
      </Sign1>

      {/* <div style={{ marginTop: "20vh" }}> */}
      {/* <Footer /> */}
      {/* </div> */}
    </>
  );
}

export default Signin;

const Sign1 = styled.div`
  .TotalWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
  }
  .Sign1 {
    display: flex;
    justify-content: center;
  }
  /* Styles for the page background */
  body {
    background-color: #e5e4e2;
  }

  /* Styles for the form wrapper */
  .formwrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 50px;
    margin-right: 50px;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  /* Styles for the content */
  .content {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
    margin-left: 25px;
  }
  .small-content {
    font-size: 16px;
    // font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
    margin-left: 25px;
  }
  input.error {
    border: 1px solid red;
  }

  .error-message {
    color: red;
    margin-bottom: 15px;
    font-size: 16px;
  }
  /* Styles for the form */
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Styles for the form labels */
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333333;
  }

  /* Styles for the form inputs */
  input[type="txt"],
  input[type="password"] {
    height: 30px;
    width: 300px;
    margin-bottom: 20px;
    padding: 5px;
  }

  /* Styles for the submit button */
  button[type="submit"] {
    font-size: 16px;
    color: white;
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #297fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .login-button {
    font-size: 16px;
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #297fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
    border-top: 4px solid white; /* White color */
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Styles for the "Don't have an account?" and "Forgot Password?" links */
  div > a {
    margin-top: 20px;
    text-decoration: none;
    color: #333333;
  }

  /* Optional hover effect for links */
  div > a:hover {
    text-decoration: underline;
  }
`;
