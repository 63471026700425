import React from "react";
import { styled } from "styled-components";
// import Footer from "../pages/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
// import CreditRecommendationReport from "./CreditRecommendationReport";

function RequestSettlement() {
  const [creditScoreReportCheck, setCreditScoreReportCheck] =
    React.useState(false);
  const { state } = useLocation();
  let navigate = useNavigate();

  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3
                style={{
                  marginBottom: "5px",
                  color: "#4A3AFF",
                  marginTop: "0px",
                }}
                className="section-heading"
              >
                {" "}
                Settlement Details
              </h3>
            </div>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Disbursement amount to you</th>
                    <td>2,24,146</td>
                  </tr>
                  <tr>
                    <th>Disbursal status</th>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <th>Disbursed date</th>
                    <td>September 22, 2024</td>
                  </tr>
                  <tr>
                    <th>UTR</th>
                    <td>2571142314</td>
                  </tr>
                  {/* <tr>
                    <th></th>
                    <td></td>
                  </tr> */}
                  <tr>
                    <th>Amount repaid by you</th>
                    <td>2,88,725</td>
                  </tr>
                  <tr>
                    <th>Repayment date</th>
                    <td>October 24, 2024</td>
                  </tr>
                  <tr>
                    <th>Repayment status</th>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <th>UTR</th>
                    <td>4571157614</td>
                  </tr>
                  <tr>
                    <th>Transaction charges</th>
                    <td>0.70%</td>
                  </tr>
                  <tr>
                    <th>Interest charges</th>
                    <td>18.88%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </RequestInformation>
      </div>

      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestSettlement;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
  }
  .custom-link:hover {
    color: #4a3aff;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    width: 55%;
  }
  table input {
    width: 100%;
    padding: 10px 0px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
