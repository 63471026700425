import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { callAPI } from "../../API/FP/Actions";
import { contextObj, dateFormat, requestStates } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import { PlacedRequestInvoicesData } from "../../MockData/AnchorTraderMock";

function RequestDetails({ creditScoreReportCheck, setCreditScoreReportCheck }) {
  const location = useLocation();
  const requestData = location.state?.requestData || {};
  const token = location.state?.authToken;
  const [tradeDetails, setTradeDetails] = useState([]);
  const [auth, setAuth] = useAuth();

  // console.log("Request Data", requestData)

  useEffect(() => {
    const fetchTradeData = async () => {
      try {
        const constructObj = contextObj("paisabuddy", "fetch", "tradesList");
        const tradesResponse = await callAPI(
          auth?.token,
          constructObj,
          "trades"
        );

        if (tradesResponse.res.status === 200) {
          const filteredData =
            tradesResponse.res.data.data.message.msgDetails.tradesList.filter(
              (trade) =>
                trade.financerequest?.id &&
                trade.financerequest.id === requestData.financerequest.id
            );
          setTradeDetails(filteredData);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      }
    };

    // fetchTradeData();
  }, [requestData, auth?.token]);

  let tableHeaderNames = [
    "Invoice Date",
    "Invoice Number",
    "Trade Partner",
    // "Anchor Trader",
    "Trade Amount (INR)",
    "Term (Days)",
  ];

  return (
    <>
      <div>
        <Dash className="body-text">
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Trade Details
            </h3>
            <div>
              <table style={{ width: "90% important" }}>
                <tbody>
                  <tr>
                    {tableHeaderNames.map((x) => {
                      return <th>{x}</th>;
                    })}
                  </tr>

                  {PlacedRequestInvoicesData.map((item) => {
                    return (
                      <tr>
                        <td>{dateFormat(new Date(item.invoiceDate), 0)}</td>
                        <td>{item.invoiceNumber}</td>
                        <td>{item.tradePartnerName}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={item.tradeAmount}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </td>
                        <td>{item.invoiceTerm}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Dash>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestDetails;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 40% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

const Dash = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90% !important;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
