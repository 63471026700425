import { useAuth } from "../../context/auth";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from "./Signin";
import Signup from "./Signup";
import SetPassword from "./SetPassword";

const SignInLayout = () => {
  const [auth, setAuth] = useAuth();

  return (
    <Routes>
      <Route path="/creditbazaar/signup" element={<Signup />} />
      <Route path="/creditbazaar/passwordsetup" element={<SetPassword />} />
      <Route path="*" element={<Signin />} />
    </Routes>
  );
};

export default SignInLayout;
