import "../App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Signin from "../components/auth/Signin";
import Signup from "../components/auth/Signup";
import UserProfile from "../components/auth/UserProfile";
import { styled } from "styled-components";
import { useAuth } from "../context/auth";
import ForgotPassword from "../components/auth/ForgotPassword";
import Reset from "../components/auth/Reset";
import Profile from "../components/Profile";
import FinanceRequestInformation from "../components/finance/FinanceRequestInformation";
import FinanceRequestIndividual from "./finance/FinanceRequestIndividual";
import FinanceProfileOverview from "./financeProfile/FinanceProfileOverview";
import TradeProfileIndividual from "./tradepartnerProfile/TradeProfileIndividual";
import SellerProfileIndividual from "./sellerProfile/SellerProfileIndividual";
import RequestLogs from "./finance/RequestLogs";
import RequestLogIndividual from "./finance/RequestLogIndividual";
import TradePartnerRequestInformation from "./TradePartner/TradePartnerRequestInformation";
import TradePartnerRequestIndividual from "./TradePartner/TradePartnerRequestIndividual";
import TradePartnerRequestLogs from "./TradePartner/TradePartnerRequestLogs";
import TradePartnerRequestLogIndividual from "./TradePartner/TradePartnerRequestLogIndividual";
import AnchorTraderRequestInformation from "./AnchorTrader/AnchorTraderRequestInformation";
import PlacedRequests from "./AnchorTrader/PlacedRequests";
import PlacedRequestIndividual from "./AnchorTrader/PlacedRequestIndividual";
import ProfileIndividual from "./AnchorTrader/Profile/ProfileIndividual";
import ComingSoon from "./API/Common/ComingSoon";
import ATTrades from "./AnchorTrader/ATTrades";
import AtDashBoard from "./AnchorTrader/AtDashBoard";

function Home() {
  const [auth, setAuth] = useAuth();

  return (
    <App1>
      <Routes>
        <Route path="/creditbazaar/" element={<FinanceRequestInformation />} />
        <Route path="/creditbazaar/signup" element={<Signup />} />
        <Route path="/creditbazaar/profile" element={<UserProfile />} />
        <Route
          path="/creditbazaar/forgotpassword"
          element={<ForgotPassword />}
        />
        <Route path="/creditbazaar/reset-password" element={<Reset />} />
        <Route path="/creditbazaar/user-profile" element={<Profile />} />
        ////////////////////// AT Routes//////////////////////////////
        <Route
          path="/creditbazaar/anchorTrader-profile"
          element={<ProfileIndividual />}
        />
        <Route
          path="/creditbazaar/anchorTrader-DashBoards"
          element={<AtDashBoard />}
        />
        <Route
          path="/creditbazaar/create-request"
          element={<AnchorTraderRequestInformation />}
        />
        <Route
          path="/creditbazaar/placed-requests"
          element={<PlacedRequests />}
        />
        <Route path="/creditbazaar/attrades" element={<ATTrades />} />
        <Route
          exact
          path="/creditbazaar/placed-requests/:id/"
          element={<PlacedRequestIndividual />}
        />
        ////////////////////////////// End AT Routes ///////////////////////////
        ////////////////////// TP Routes//////////////////////////////
        <Route
          path="/creditbazaar/buyer-request-information"
          element={<TradePartnerRequestInformation />}
        />
        <Route
          exact
          path="/creditbazaar/buyer-request-information/:id/"
          element={<TradePartnerRequestIndividual />}
        />
        <Route
          exact
          path="/creditbazaar/buyer-request-information/request-log/"
          element={<TradePartnerRequestLogs />}
        />
        <Route
          exact
          path="/creditbazaar/buyer-request-information/request-log/:id/"
          element={<TradePartnerRequestLogIndividual />}
        />
        <Route
          path="/creditbazaar/tradePartner-profile"
          element={<TradeProfileIndividual />}
        />
        ////////////////////////////// End TP Routes ///////////////////////////
        ////////////////////// FP Routes//////////////////////////////
        <Route
          path="/creditbazaar/offer-information"
          element={<FinanceRequestInformation />}
        />
        {/* <Route
          exact
          path="/offer_information/:id/"
          element={ <FinanceRequestIndividual />}
        /> */}
        <Route
          exact
          path="/creditbazaar/placed-offers/"
          element={<RequestLogs />}
        />
        <Route
          exact
          path="/creditbazaar/placed-offers/:id/"
          element={<RequestLogIndividual />}
        />
        <Route
          path="/creditbazaar/financesdashboard"
          element={<ComingSoon />}
        />
        <Route path="/creditbazaar/workflow" element={<ComingSoon />} />
        <Route path="/creditbazaar/faq" element={<ComingSoon />} />
        <Route path="/creditbazaar/support" element={<ComingSoon />} />
        ////////////////////////////// End FP Routes ///////////////////////////
      </Routes>
    </App1>
  );
}

export default Home;

const App1 = styled.div`
  .topcomp {
    //  position:fixed;
    display: flex;
    height: 60px;
    margin-left: 50px;
    width: 300px;
    justify-content: center;
    margin-top: -80px;
  }
  .leftcontent {
    // position:fixed;
    margin-top: 10px;
    font-size: 25px;
  }
`;
