import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import CreditRecommendationReport from "./CreditRecommendationReport";
import {
  callAPI,
  patchOfferStatus,
  sendPlacedOfferDetails,
} from "../API/FP/Actions";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import { contextObjForPlacingOffer } from "../Common/Constants";

import {
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
function FinanceRequestIndividual({
  data,
  actionDateFunction,
  creditScoreColor,
  creditScoreReportCheck,
  setCreditScoreReportCheck,
  getCreditScore,
  authToken,
}) {
  // const [creditScoreReportCheck, setCreditScoreReportCheck] =
  //   React.useState(false);
  let navigate = useNavigate();

  const [interestPercentage, setInterestPercentage] = useState(
    data?.interestPtg ?? 0
  );
  const [interestValue, setInterestValue] = useState(data?.interestValue ?? 0);
  const [selectedReason, setSelectedReason] = useState(null);
  const [showOtherField, setShowOtherField] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const reasons = [
    " Did not like the Anchor Trader",
    "The Requested amount is not within the permissable limits",
    "The Intrest rate is low",
    "The Credit score is low",
  ];
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [amountToBeDisbursed, setAmountToBeDisbursed] = useState(
    data?.netAmount ?? 0
  );
  const [auth, setAuth] = useAuth();
  const inputRef = useRef(null);
  const handleSelectReason = (reason) => {
    if (reason === "Other") {
      setShowOtherField(true);
      setSelectedReason("Other");
    } else {
      setSelectedReason(reason);
      setShowOtherField(false); // Hide other field when a predefined reason is selected
    }
  };

  const handleRemoveReason = () => {
    setSelectedReason(null);
    setShowOtherField(false);
    setOtherReason("");
  };

  const handleOtherReason = () => {
    setShowOtherField(true);
    setSelectedReason("Other");
  };

  const svaereason = async (finalReason, id) => {
    closepopup();
  };
  const handleSave = () => {
    const finalReason = showOtherField ? otherReason : selectedReason;
    svaereason(finalReason, data?.requestOffer?.id);
    navigate("/creditbazaar/placed-offers");
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const openpopup = () => {
    setpopupbtnopen(true);
  };

  const closepopup = () => {
    setpopupbtnopen(false);
  };
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;

  const submitOffer = async () => {
    const placedOfferObj = {
      reqOffId: data?.reqOffId,
      // id: data?.id,
      value: data?.offerValue,
      reqAmount: data?.requestAmt,
      disbursalAmount: amountToBeDisbursed,
      marginPtg: data?.marginPtg,
      marginValue: data?.marginValue,
      amountAftMargin: data?.amountAftMargin,
      interestPtg: parseInt(interestPercentage),
      term: data?.term,
      financerequest: data?.financerequest,
      interestValue: parseInt(interestValue),
      netAmount: parseInt(amountToBeDisbursed),
      status: "AWAITING_ACCEPTANCE",
      offerDate: formattedToday,
      placedOfferRefNo: data?.requestOfferRefNo,
      // placedOfferId: `PL-31-${Date.now()}`,
      // financeRequestDate: data?.financeRequestDate,
      anchorTrader: data?.anchorTraderName,
      tradePartner: data?.tradePartnerName,
      // cbcreprocess: data?.cbcreprocess,
    };
    const requestOfferObj = {
      id: data?.id,
      reqOffId: data?.reqOffId,
      offerValue: data?.offerValue,
      requestAmt: data?.requestAmt,
      tradeValue: data?.tradeValue,
      marginPtg: data?.marginPtg,
      marginValue: data?.marginValue,
      amountAftMargin: data?.amountAftMargin,
      interestPtg: data?.interestPtg,
      term: data?.term,
      financerequest: data?.financerequest,
      interestValue: data?.interestValue,
      netAmount: data?.netAmount,
      status: "AWAITING_ACCEPTANCE",
      anchorTraderName: data?.anchorTraderName,
      tradePartnerName: data?.tradePartnerName,
      anchorTraderGst: data?.anchorTraderGst,
      tradePartnerGst: data?.tradePartnerGst,
      anchorTraderGSTComplianceScore: data?.anchorTraderGSTComplianceScore,
      anchorTraderGSTERPPeerScore: data?.anchorTraderGSTERPPeerScore,
      cbcreprocess: data?.cbcreprocess,
      financeRequestDate: data?.financeRequestDate,
      requestOfferRefNo: data?.requestOfferRefNo,
      anchorTrader: data?.anchorTraderName,
      tradePartner: data?.tradePartnerName,
    };

    // const response = await patchOfferStatus(auth?.token, requestOfferObj);
    // const response1 = await sendPlacedOfferDetails(auth?.token, placedOfferObj);

    navigate("/creditbazaar/placed-offers");
  };
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  return (
    <>
      {!creditScoreReportCheck && (
        <div>
          <div
            style={{
              marginTop: "2%",
              // textAlign: "end",
              marginRight: "5%",
            }}
          >
            <Tooltip title=" Coming Soon" placement="bottom">
              {" "}
              <button
                style={{
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  padding: "10px 10px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  height: "fit-content",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
                // onClick={() => setCreditScoreReportCheck(true)}
              >
                KYC Documents
              </button>
            </Tooltip>

            <Tooltip title="Coming Soon" placement="bottom">
              <button
                style={{
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  padding: "10px 10px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  height: "fit-content",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
                // onClick={() => setCreditScoreReportCheck(true)}
              >
                Trade Documents
              </button>
            </Tooltip>
            <button
              style={{
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 10px",
                cursor: "pointer",
                borderRadius: "5px",
                height: "fit-content",
                fontSize: "11px",
              }}
              onClick={() => setCreditScoreReportCheck(true)}
            >
              Credit Recommendation Report
            </button>
          </div>
          <RequestInformation
            className="body-text"
            style={{ display: "flex", marginTop: "1%" }}
          >
            <div className="main-content">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3
                  style={{ marginBottom: "5px", color: "#4A3AFF" }}
                  // className="section-heading"
                >
                  {" "}
                  Request Details
                </h3>
              </div>
              <div className="record-details">
                <table>
                  <tbody>
                    <tr>
                      <th>Action By Date</th>
                      <td>
                        {actionDateFunction(
                          new Date(data?.financeRequestDate),
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Credit Score</th>
                      <td>
                        <span
                          style={{
                            // color: creditScoreColor(
                            //   getCreditScore(data?.tradePartnerGst)
                            // ),
                            fontWeight: "700",
                          }}
                        >
                          {" "}
                          {data?.creditScore}/100
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Anchor Trader</th>
                      <td>{data?.anchorTraderName}</td>
                    </tr>
                    <tr>
                      <th>Trade Partner</th>
                      <td>{data?.tradePartnerName}</td>
                    </tr>
                    <tr>
                      <th> Total trade value (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={data?.tradeValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Requested Tenure (Days)</th>
                      <td> {data?.term}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="main-content" style={{ marginLeft: "5%" }}>
              <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                {" "}
                Final Offer Details
              </h3>
              <div className="record-details">
                <table>
                  <tbody>
                    <tr>
                      <th>Margin Cut (%)</th>
                      <td>{data?.marginPtg}%</td>
                    </tr>
                    <tr>
                      <th>Margin value (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={data?.marginValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Amount after margin (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={data?.amountAftMargin}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th> Annualized Interest rate (%)</th>
                      <td style={{ padding: "0px" }}>
                        <input
                          type="number"
                          name="fname"
                          value={interestPercentage}
                          min="0"
                          onKeyPress={preventMinus}
                          ref={inputRef}
                          onChange={(e) => {
                            setInterestPercentage(e.target.value);
                            if (e.target.value) {
                              const t =
                                (data?.amountAftMargin *
                                  e.target.value *
                                  data?.term) /
                                (365 * 100);
                              setInterestValue(t.toFixed(0));
                              setAmountToBeDisbursed(
                                (data?.amountAftMargin - t).toFixed(0)
                              );
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Interest value (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={interestValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Amount to be disbursed (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={amountToBeDisbursed}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <th>Total Repayment amount (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={data?.amountAftMargin}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </RequestInformation>
          <div
            style={{
              marginTop: "30px",
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "5px",
                height: "fit-content",
                opacity: interestPercentage ? "1" : "0.5",
              }}
              disabled={interestPercentage ? false : true}
              onClick={() => submitOffer()}
            >
              Place an Offer
            </button>
            <div style={{}}>
              {" "}
              <button
                style={{
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  height: "fit-content",
                }}
                onClick={openpopup}
              >
                Reject Offer
              </button>
            </div>

            <Dialog
              open={popupbtnopen}
              onClose={closepopup}
              maxWidth="sm"
              PaperProps={{
                style: { borderRadius: 20, width: "100%" },
              }}
            >
              <DialogTitle
                style={{ backgroundColor: "#4A3AFF", color: "white" }}
              >
                Reject the offer
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closepopup}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "white",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                <DialogContentText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                      gap: "10px",
                      color: "#4A3AFF",
                    }}
                  >
                    <Typography style={{ fontSize: "17px" }}>
                      Tell us more :
                    </Typography>

                    <div className="record-details">
                      {selectedReason && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {selectedReason && selectedReason !== "Other" && (
                            <IconButton>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      )}

                      {!selectedReason && !showOtherField && (
                        <div
                          style={{
                            /*display: "grid",
                            gridTemplateColumns: "1fr 1fr",*/
                            gap: "10px",
                          }}
                        >
                          {reasons.map((reason) => (
                            <button
                              key={reason}
                              variant="contained"
                              style={{
                                margin: "5px",
                                backgroundColor: "white",
                                border: "1px solid #babdc0",
                                color: "#8b8e92",
                                fontWeight: "bold",
                                borderRadius: "15px",
                                padding: "15px",
                                transition: "color 0.3s",
                              }}
                              disabled={!!selectedReason}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.color = "black")
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.color = "#8b8e92")
                              }
                            >
                              {reason}
                            </button>
                          ))}
                          <button
                            variant="contained"
                            style={{
                              margin: "5px",
                              backgroundColor: "white",
                              border: "1px solid #babdc0",
                              color: "#8b8e92 ",
                              fontWeight: "bold",
                              borderRadius: "50px",
                              padding: "15px",
                              transition: "color 0.3s",
                            }}
                            disabled={!!selectedReason}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.color = "black")
                            }
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.color = "#8b8e92")
                            }
                            onClick={handleOtherReason}
                          >
                            More ...
                          </button>
                        </div>
                      )}
                      {showOtherField && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginTop: "15px",
                          }}
                        >
                          <TextField
                            label="Other Reason"
                            variant="standard"
                            fullWidth
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                            InputProps={{
                              disableUnderline: false,
                            }}
                            style={{ flex: 1 }}
                          />
                          {selectedReason && (
                            <IconButton onClick={handleRemoveReason}>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      type="button"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "8px 8px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        margin: "5px",
                        width: "100px",
                        position: "absolute",
                        right: 8,
                        bottom: 8,
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        </div>
      )}
      {creditScoreReportCheck && (
        <CreditRecommendationReport
          creditScoreReportCheck={creditScoreReportCheck}
          setCreditScoreReportCheck={setCreditScoreReportCheck}
          financeRequestData={data}
          authToken={authToken}
        />
      )}
      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default FinanceRequestIndividual;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }
  .main-content {
    width: 45%;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    width: 55%;
  }
  table input {
    width: 100%;
    padding: 9px 0px 9px 5px;
    font-size: 14px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
